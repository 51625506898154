import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {

    createProduct () {
        return new Swiper('#slider--product', {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--product-next',
                prevEl: '.slider--product-prev',
            },
            pagination: {
                el: '.swiper-pagination--product',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProduct2 () {
        return new Swiper('#slider--product2', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--product2-next',
                prevEl: '.slider--product2-prev',
            },
            pagination: {
                el: '.swiper-pagination--product2',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProduct3 () {
        return new Swiper('#slider--product3', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--product3-next',
                prevEl: '.slider--product3-prev',
            },
            pagination: {
                el: '.swiper-pagination--product3',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProduct4 () {
        return new Swiper('#slider--product4', {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--product4-next',
                prevEl: '.slider--product4-prev',
            },
            pagination: {
                el: '.swiper-pagination--product4',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createReviewVideo () {
        return new Swiper('#slider--review-video', {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--review-video-next',
                prevEl: '.slider--review-video-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-video',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createReviewText () {
        return new Swiper('#slider--review-text', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--review-text-next',
                prevEl: '.slider--review-text-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-text',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createSert () {
        return new Swiper('#slider--sert', {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--sert-next',
                prevEl: '.slider--sert-prev',
            },
            pagination: {
                el: '.swiper-pagination--sert',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createArticle () {
        return new Swiper('#slider--article', {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--article-next',
                prevEl: '.slider--article-prev',
            },
            pagination: {
                el: '.swiper-pagination--article',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createGallery (containerItem, containerThumbs) {
        return new Swiper('.' + containerItem, {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.' + containerItem + '-next',
                prevEl: '.' + containerItem + '-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            thumbs: {
              swiper: this.createThumbs(containerThumbs)
            },
            breakpoints: {}
        })
    }

    createThumbs (containerItem) {
        return new Swiper('.' + containerItem, {
            slidesPerView: 4,
            spaceBetween: 15,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.' + containerItem + '-next',
                prevEl: '.' + containerItem + '-prev',
            },
            pagination: {
                el: '.swiper-pagination--thumbs',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--product')!= null) {
            this.createProduct()
        }

        if(document.getElementById('slider--product2')!= null) {
            this.createProduct2()
        }

        if(document.getElementById('slider--product3')!= null) {
            this.createProduct3()
        }

        if(document.getElementById('slider--product4')!= null) {
            this.createProduct4()
        }

        if(document.getElementById('slider--review-video')!= null) {
            this.createReviewVideo()
        }

        if(document.getElementById('slider--review-text')!= null) {
            this.createReviewText()
        }

        if(document.getElementById('slider--sert')!= null) {
            this.createSert()
        }

        if(document.getElementById('slider--article')!= null) {
            this.createArticle()
        }

        document.querySelectorAll(".slider--gallery").forEach((item, index) => {
            this.createGallery('slider--gallery-' + (index + 1), 'slider--thumbs-' + (index + 1))
        })
    }
}
