/*global $*/
/*eslint no-undef: "error"*/
export default class Ref {
    classButton = 'ref--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = parseInt($(this).data('block'))

            if (block === 1) {
                document.body.innerHTML = ''
            }

            if (block === 2) {
                let reloadUrl = window.location
                window.location = reloadUrl
                // window.location.reload(true)
            }

            return false
        })
    }
}
